<template>
  <aside :class="['menu', {'expandido': mostarMenu}, {'comprimido': comprimirMenu}]">
    <div class="encabezado">
      <router-link :to="{name: 'Pagos'}">
        <div class="img">
          <img
            src="/img/icons/apple-touch-icon.png"
            alt="Logo de Industrias KEL"
          >
        </div>
      </router-link>
      <button
        class="btn btn-gradient-kel ripple-parent m-1 px-3 py-3 d-lg-none"
        :title="mostarMenu ? 'Ocultar menú' : 'Mostrar menú'"
        @click="mostarMenu = !mostarMenu"
      >
        <font-awesome-icon
          :icon="mostarMenu ? 'times' : 'bars'"
          size="lg"
          class="icono"
        />
      </button>
      <button
        :class="['btn', comprimirMenu ? 'btn-gradient-kel' : 'btn-flat',
          'ripple-parent m-1 px-3 py-3 d-none d-lg-block']"
        :title="comprimirMenu ? 'Expandir menú' : 'Comprimir menú'"
        @click="comprimirMenu = !comprimirMenu; $emit('alerta-mensaje',{
          contenido: comprimirMenu ? 'Menú comprimido' : 'Menú expandido',
          tipo: 'correcto',
        })"
      >
        <font-awesome-icon
          :icon="comprimirMenu ? 'bars' : 'ellipsis-v'"
          size="lg"
          class="icono"
        />
      </button>
    </div>
    <nav>
      <a
        v-for="(enlace, e) in enlaces"
        :key="e"
        :class="[{'d-none': enlace.deshabilitar}]"
        @click="cerrarMenu(enlace.ruta)"
      >
        <font-awesome-icon :icon="enlace.icono" class="mx-2" />
        <span class="enlace">{{ enlace.nombre }}</span>
      </a>
    </nav>
    <footer>
      <span class="title">
        © SisKEL Cobranza
      </span>
      Versión {{ fechaCompilacion() }}
    </footer>
      <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </aside>
</template>

<script>
import { apiPost } from '@/funciones/api.js'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
export default {
  name: 'MenuComp',
  components: {
    AlertaMensaje
  },
  data () {
    return {
      alertaMensaje: { contenido: '' },
      enlaces: [
        {
          nombre: 'Clientes',
          ruta: '/clientes',
          icono: 'users',
          deshabilitar: false
        },
        {
          nombre: 'Pagos',
          ruta: '/',
          icono: 'hand-holding-dollar',
          deshabilitar: false
        },
        {
          nombre: 'Facturas',
          ruta: '/facturas',
          icono: 'file-invoice',
          deshabilitar: false
        },
        {
          nombre: 'Reportes',
          ruta: '/reportes',
          icono: 'chart-bar',
          deshabilitar: false
        },
        {
          nombre: 'Salir',
          ruta: '/login',
          icono: 'sign-out-alt',
          deshabilitar: false
        }
      ],
      // Manipulación del Menú
      comprimirMenu: false,
      mostarMenu: false
    }
  },
  methods: {
    cerrarMenu (ruta) {
      if (this.mostarMenu) this.mostarMenu = false
      if (ruta === '/login') this.logout()
      else if (this.$route.path !== ruta) this.$router.push(ruta)
    },
    fechaCompilacion () {
      const metaArray = document.head.querySelector('[name=updated]').content
        .split(' ')
      let mes
      switch (metaArray[1]) {
        case 'Jan':
          mes = '01'
          break
        case 'Feb':
          mes = '02'
          break
        case 'Mar':
          mes = '03'
          break
        case 'Apr':
          mes = '04'
          break
        case 'May':
          mes = '05'
          break
        case 'Jun':
          mes = '06'
          break
        case 'Jul':
          mes = '07'
          break
        case 'Aug':
          mes = '08'
          break
        case 'Sep':
          mes = '09'
          break
        case 'Oct':
          mes = '10'
          break
        case 'Nov':
          mes = '11'
          break
        default:
          mes = '12'
          break
      }
      return [metaArray[2], mes, metaArray[3]].join('/') +
        ' ' + metaArray[4].substring(0, 5)
    },
    logout () {
      apiPost({ s: 'salir' }, '')
        .then(r => {
          if (r.status === 200) {
            this.$router.push('/login')
          }
        }).catch(() => {
          this.alertaMensaje = {
            contenido: 'No se pudo cerrar la sesión',
            tipo: 'error'
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
// Posicionamiento del botón Menú
.btn-flat,
.btn-gradient-kel {
  position: absolute;
  right: -4rem;
  top: .5rem;
  transition: right .5s ease;

  @media screen and (min-width: 992px) {right: -4px;}

  .icono {width: 15px;}
}

// Menú
.menu {
  background-color: #FFF;
  box-shadow: 0 0 .125rem rgba(0, 0, 0, .15);
  position: fixed;
  transition-duration: .5s;
  transition-property: transform, width;
  transition-timing-function: ease;
  width: 205px;
  z-index: 100;

  @media screen and (max-width: 991px) {transform: translateX(-100%);}

  &.expandido {
    @media screen and (max-width: 991px) {
      transform: translateX(0);

      .btn-gradient-kel {right: -1.75rem;}
    }
  }

  &.comprimido {
    @media screen and (min-width: 992px) {
      width: 64px;

      .encabezado img,
      nav a .enlace,
      footer {opacity: 0;}

      .btn-gradient-kel {right: 4px;}

      &:hover {
        width: 205px;

        .encabezado img,
        nav a .enlace,
        footer {opacity: 1;}

        .btn-gradient-kel {right: -1.75rem;}
      }
    }
  }
}

// Encabezado del Menú
.menu .encabezado {
  position: relative;
  text-align: center;

  .img {overflow: hidden;}

  img {
    opacity: 1;
    transition: opacity .45s ease-out;
  }
}

// Footer del Menú
.menu footer {
  font-size: .75rem;
  overflow: hidden;
  padding: 1rem;
  text-align: center;
  transition: opacity .5s ease;
  user-select: none;
  white-space: nowrap;

  .title {
    display: block;
    letter-spacing: 2px;
  }
}

// Enlaces del nav
nav {
  display: flex;
  flex-direction: column;
  height: calc(100vh - (180px + 68px)); // - (encabezado + footer)
  overflow: hidden auto;
  padding: 0 .9rem 1.25rem;

  a {
    border-bottom: 2px solid transparent;
    color: $texto;
    font-size: .8rem;
    font-weight: 500;
    margin: .25rem 0;
    padding: .75rem .25rem;
    text-transform: uppercase;
    transition-duration: .5s;
    transition-property: border-bottom-color, color;
    transition-timing-function: ease;
    white-space: nowrap;
  }
  .enlace {
    opacity: 1;
    transition: opacity .5s ease;
  }
  .router-link-exact-active {
    border-bottom-color: $primario;
    color: $primario;
  }
  a:hover,
  .router-link-exact-active:hover {
    border-bottom-color: $secundario;
    color: $secundario;
  }
}
</style>
